import React from "react";
import img1 from "../../../Static/icons/img1.svg";
import img2 from "../../../Static/icons/img2.svg";
import img3 from "../../../Static/icons/img3.svg";
const HomeServiceCard = () => {
  return (
    <div className="container pt-5 ">
      <div className="row w-100 d-flex justify-content-center">
        <div className="col-lg-3  w-100 d-flex justify-content-start flex-column p-3 ">
          <img src={img1} alt="" width={80} height={80} />
          <h4 className="mt-2">Sell</h4>
          <p style={{ color: "gray" }}>
           Looking to Sell you Land.? Dream Finder will help you sell your Land with greate offers.{" "}
          </p>
        </div>
        <div className="col-lg-3  d-flex justify-content-start  flex-column p-3 ">
          <img src={img3} alt="" width={80} height={80} />
          <h4 className="mt-2">Purchase</h4>
          <p style={{ color: "gray" }}>
            Want to purchase a land.? Dream Finder will help you find your Dream
            Home.{" "}
          </p>
        </div>
        <div className="col-lg-3 d-flex justify-content-start flex-column p-3 ">
          <img src={img2} alt="" width={80} height={80} />
          <h4 className="mt-2">Documentation</h4>
          <p style={{ color: "gray" }}>
            Feeling overwhelmed with all those Documentation.No need to worry
            Dream Finder will take care of all those documents for you{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeServiceCard;
