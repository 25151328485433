import React from "react";

const Certificates = () => {
  function importAll(data) {
    let images = {};
    data.keys().map((item, index) => {
      images[item.replace("./", "")] = data(item);
      return "";
    });
    return images;
  }
  const images = importAll(
    require.context(
      "../../Static/certificates",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  );
  const imagesData = Object.keys(images);
  return (
    <>
      <div className="row About-header p-5 mx-0 ">
        <div className="container">
          <div className="col-sm-12">
            <h4 className="about-header-text">Certificates</h4>
          </div>
        </div>
      </div>
      <div className="flex-center" style={{ height: "100vh", width: "100%" }}>
        <div className="container">
          <div className="row ">
            {imagesData?.map((item, index) => {
              return (
                <div class="col-lg-4 col-md-6 col-sm-12 mt-5 center-childrens">
                  <a data-fancybox="gallery" href={images[item].default}>
                    <img
                      width="350px"
                      height="250px"
                      alt=""
                      src={images[item].default}
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificates;
