import bahriaTownImage from "../Static/behriaTown/Bahria-Town-Karachi-Logo.jpg";
import blueWorldImage from "../Static/blueWorld/logo.png";
import hawksbayImage from "../Static/HAWKSBAYSCHEME42/logoHawksbay.png";
import profileImage from "../Static/images/profile.png";

export const bahriaTown = {
  bannerImage: bahriaTownImage,
  projectHeading: "Bahria Town",
  subHeading: "120 | 240 | 400 | 800 Yards",
  paragraph:
    "Bahria Town Karachi is a privately owned gated suburb under construction near Karachi, Pakistan. The suburb is being developed by the Bahria Town Group, and occupies under 46,000 acres, just off the M-9 Motorway northeast of Karachi.",
  gallery: "Project Gallery",
};
export const blueWorld = {
  bannerImage: blueWorldImage,

  projectHeading: "Blue World",
  subHeading: "120 | 240 | 400 | 800 Yards",
  paragraph:
    "Blue World City Islamabad is the top-ranking housing society in Islamabad. It is developing by the Blue Group of Companies (BGC) in consultation with world-renowned Shan Jian Municipal Engineering Company and located right next to the Chakri Interchange & newly proposed Rawalpindi Ring Road.",
  gallery: "Project Gallery",
};
export const hawksbayScheme42 = {
  bannerImage: hawksbayImage,

  projectHeading: "Hawksbay Scheme 42",
  subHeading: "80 | 120 | 240 | 400 yard Residential plots",
  paragraph:
    "Its a very good time to invest in Hawks bay Scheme-42 Karachi. Hawks bay scheme-42 is planned, developed, announced by Karachi Development authority. It is located on Pakistan Coastal Belt, Hawks bay, Karachi. Two reasons always rank high in property prices in Pakistan e. g, Security and Sea View. Gain in Coastal Belt have always high comparing other areas e. g, property prices in Defense Karachi with other areas, property prices in Gwadar comparing other areas. Security situations and Sea view Location in Defense, Karachi and Gwadar rank high in prices vis a via in gain in property businesses. Future security situations and control in Hawks bay Scheme-42 Karachi would be highest comparing other areas of Karachi. kanupp karachi (Electricity Power Plant), SUPARCO (Weather), Masror Base, Power Plant On Coastal Belt Hawks bay Paradise Point, Naval Area On Hawks Bay.",
  gallery: "Project Gallery",
};

export const projectCardData = [
  [
    {
      bahriaTownImage: hawksbayImage,
      bahriaTownHeading: "Hawksbay Scheme 42",
      bahriaTownDetails:
        "Hawke's Bay Town is located in Keamari Town in Karachi, Sindh, Pakistan. Hawke's Bay Town was notified in July, 1984 by KDA. ",
    },
  ],
  [
    {
      bahriaTownImage: blueWorldImage,
      bahriaTownHeading: "Blue World City",
      bahriaTownDetails:
        "Blue World City Islamabad is the top-ranking housing society in Islamabad. It is developing by the Blue Group of Companies (BGC).",
    },
  ],
  [
    {
      bahriaTownImage: bahriaTownImage,
      bahriaTownHeading: "Bahria Town Karachi",
      bahriaTownDetails:
        "Bahria Town Karachi is a privately owned gated suburb under construction near Karachi, Pakistan. The suburb is being developed by the Bahria Town Group.",
      
    },
  ],
 
];

export const teamCardData = [
  [
    {
      userImage: profileImage,
      userName: "Shabbir Ali",
      userDesignation: "Co-Founder",
      userDetails:
        "Mr. Shabbir Ali is an eminent businessman working in the field since last 35 years.",
    },
  ],
  [
    {
      userImage: profileImage,
      userName: "Usama Hameed",
      userDesignation: "Co-Founder",
      userDetails:
        "Mr. Usama Hameed is known in the industry for his reputation of pushing and motivating the entire team further, making sure the job is well done.",
      //   blueWorldHeading: "Blue World City",
      //   blueWorldDetails:
      //     "This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card wit",
      //   hawksbaydHeading: "Hawksbay Scheme 42",
      //   hawksbaydHeadingDetails:
      //     "This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card wit",
    },
  ],
  [
    {
      userImage: profileImage,
      userName: "Uzair Ahmed",
      userDesignation: "Co-Founder",
      userDetails:
        "Mr. Uzair Ahmed is highly respected by his peers and in business ventures, in the real estate market. He is the person who remains very much involved in ABS’s day to day environment and operations.",
    },
  ],
];
