import React from 'react'

const Error404 = () => {
    return (
        <div>
           Error404 
        </div>
    )
}

export default Error404
