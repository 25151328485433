import React from "react";
import { Link } from "react-router-dom";
import facebook from "../../../Static/icons/6.png";
import youtube from "../../../Static/icons/5.png";
import insta from "../../../Static/icons/insta.png";

import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="backg">
        <div className="container">
          <footer className="text-center text-lg-start text-white">
            <div className="container p-1">
              <div className="row mt-4">
                <div className="col-lg-3 col-md-6 mb-4 mb-md-0 first1">
                  <h5 className="text-capitalize fhead">Quick Links</h5>

                  <ul
                    className="list-unstyled mb-0"
                    style={{ textDecoration: "none" }}
                  >
                    <li className="mb-2">
                      <Link to="/home" className="linkFoot">
                        Office # 425 4th Floor Mashriq Center Block-14
                        Gulshan-e-Iqbal Karachi
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/about" className="linkFoot">
                        info@dreamfinder.com
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/facilities" className="linkFoot">
                        (021) 34145028
                      </Link>
                    </li>
                    <ul className="list-unstyled list-inline mt-3">
                      <li className="list-inline-item">
                        <a
                          href="https://www.facebook.com/dreamfinderrealestate"
                          className="sbtn btn-large mx-1 text-white linkFoot"
                          title="Facebook"
                          target="_blank"
                        >
                          <img src={facebook} />
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a
                          href="https://www.youtube.com/channel/UCr4QoQ97tHT4WlZM6coe73Q"
                          target="_blank"
                          className="sbtn btn-large mx-1 text-white linkFoot"
                          title="Youtube"
                        >
                          <img src={youtube} />
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a
                          // href="https://www.youtube.com/channel/UCr4QoQ97tHT4WlZM6coe73Q"
                          // target='_blank'
                          title="Instagram"
                        >
                          <img src={insta} />
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                        <a
                          href="#!"
                          className="sbtn btn-large mx-1 text-white linkFoot"
                          title="Twitter"
                        >
                          <i className="fa fa-twitter-square fa-2x"></i>
                        </a>
                      </li> */}
                      {/* <li className="list-inline-item">
                        <a
                          href="#!"
                          className="sbtn btn-large mx-1 text-white linkFoot"
                          title="Youtube"
                        >
                          <i className="fa fa-youtube-square fa-2x"></i>
                        </a>
                      </li> */}
                    </ul>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 mb-4 mb-md-0 second2">
                  <h5 className="text-capitalize fhead">Company</h5>

                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <Link to="/" className="linkFoot">
                        Home
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/about" className="linkFoot">
                        About Us
                      </Link>
                    </li>
                    {/* <li className="mb-2">
                      <a href="#serviceId" className="linkFoot">
                        Services
                      </a>
                    </li> */}
                    <li className="mb-2">
                      <Link to="/ourTeam" className="linkFoot">
                        Our Team
                      </Link>
                    </li>
                    {/* <li className="mb-2">
                      <Link to="#" className="linkFoot">
                        Careers
                      </Link>
                    </li> */}
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 mb-4 mb-md-0 second2">
                  <h5 className="text-capitalize fhead">Information</h5>

                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <a
                        href="https://www.google.com/maps/@24.8962463,67.0754798,16z"
                        target={"_blank"}
                        className="linkFoot"
                      >
                        Sitemap
                      </a>
                    </li>
                    <li className="mb-2">
                      <Link to="/certificates" className="linkFoot">
                        Certificates
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/contact" className="linkFoot">
                        Contact Us
                      </Link>
                    </li>
                    {/* <li className="mb-2">
                      <a href="/contact" className="linkFoot">
                        Contact Us
                      </a>
                    </li> */}
                  </ul>
                </div>

                <div className="col-lg-3 col-md-12  mb-4 mb-md-0 ">
                  <h5 className="text-capitalize fhead">Subscribe</h5>

                  <ul className="list-unstyled">
                    <li className="">
                      <span className="Typograpy_lastblock_Span">
                        Enter Your Email Address To Subscribe Us
                      </span>
                    </li>
                    <li className="dk-footer-form">
                      <input
                        type="email"
                        className="form-control form-control-custom"
                        placeholder="Email Address..."
                      />
                      <button type="submit">
                        <span
                          className="btn-style-custom"
                          style={{ fontSize: "12px", fontWeight: "500" }}
                        >
                          SUBSCRIBE
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>

          <div>
            <div></div>
          </div>
        </div>
      </div>

      <div className="FooterlastContainer ">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <p className="FooterlastContainercolor m-1">
            © All Right Reserved Dream Finder.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
