import React from 'react'

const DarkOutlinedBtn = ({label,onClick}) => {
    return (
        <div className="dark-outlined-btn text-center px-5 py-2 cursor" onClick={onClick}>
           <span className="">{label}</span> 
        </div>
    )
}

export default DarkOutlinedBtn
