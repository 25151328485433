import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CenterHeading from "../ReuseableComponents/CenterHeading";
import DashboardBtnList from "../ReuseableComponents/DashboardBtnList";
import { bahriaTown } from "../../data/projectData";
import { blueWorld } from "../../data/projectData";
import { hawksbayScheme42 } from "../../data/projectData";

const ProjectsDetailPage = () => {
  const id = useParams("id");
  console.log(id.id);
  const [images, setImages] = useState({});
  function importAll(data) {
    let images = {};
    data.keys().map((item, index) => {
      images[item.replace("./", "")] = data(item);
      return "";
    });
    return images;
  }
  useEffect(() => {
    if (id.id === "21242") {
      const images = importAll(
        require.context("../../Static/behriaTown", false, /\.(png|jpe?g|svg)$/)
      );
      setImages(images);
    } else if (id.id === "76343") {
      const images = importAll(
        require.context("../../Static/blueWorld", false, /\.(png|jpe?g|svg)$/)
      );
      setImages(images);
    } else if (id.id === "31545") {
      const images = importAll(
        require.context(
          "../../Static/HAWKSBAYSCHEME422",
          false,
          /\.(png|jpe?g|svg|JPG)$/
        )
      );
      setImages(images);
    }
  }, [id.id]);

  const imagesData = Object.keys(images);

  const [selectedTab2, setSelectedTab2] = useState("Pictures");

  const tabHandler2 = (item) => {
    setSelectedTab2(item);
  };
  const buttonname3 = ["Pictures", "Videos"];

  return (
    <div className="container">
      {id.id === "21242" ? (
        <div className="row">
          <div className="col-lg-6 col-md-6 mt-5  py-2 d-flex justify-content-center">
            <img
              src={bahriaTown.bannerImage}
              alt=""
              className="img-fluid"
              // style={{ marginTop: "inherit" }}
              style={{maxHeight:'400px'}}
            />
          </div>

          <div className="col-lg-6 col-md-6 mt-5  p-5 mt-5">
            <h1>{bahriaTown.projectHeading}</h1>
            <h5 className="my-3 ">{bahriaTown?.subHeading}</h5>
            <p style={{ textAlign: "justify" }}>{bahriaTown?.paragraph}</p>
          </div>
          <div className="col-12">
            <CenterHeading title={bahriaTown.gallery} />
          </div>
          <div className="col-12"></div>
          <div className="container mb-5">
            <div className="row ">
              <div className="col-12 text-center">
                {buttonname3.map((item, index) => (
                  <div
                    className="d-flex d-inline-flex my-3 "
                    key={index + 1}
                    onClick={() => tabHandler2(item)}
                  >
                    <DashboardBtnList
                      label={item}
                      bntStyle={{
                        borderRadius:
                          index === 0
                            ? "50px 0px 0px 50px"
                            : index === buttonname3.length - 1
                            ? "0px 50px 50px 0px"
                            : "",
                      }}
                      className={
                        selectedTab2 === item
                          ? "dashboardBtnList-item-active px-4 py-2"
                          : "default-color-and-hover px-4 py-2"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            {selectedTab2 === "Pictures" ? (
              <div className="row ">
                {imagesData?.map((item, index) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-5 center-childrens">
                      <a data-fancybox="gallery" href={images[item].default}>
                        <img
                          width="350px"
                          height="250px"
                          alt=""
                          src={images[item].default}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
               <div className="col-12 flex-center p-5 m-5">
                  <p style={{fontWeight:'500' , fontSize:'20px'}} className="m-5 py-5">No Video Available Yet...</p>
               </div>
                {/* <div class="col-md-4 my-4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/3lAII-Tdriw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div> */}
              </div>
            )}
          </div>
        </div>
      ) : id.id === "76343" ? (
        <div className="row">
          <div className="col-lg-6 col-md-6 mt-5  py-2">
            <img
              src={blueWorld.bannerImage}
              alt=""
              className="img-fluid"
              style={{ minHeight: "400px" }}
            />
          </div>

          <div className="col-lg-6 col-md-6 mt-5  p-5 mt-5">
            <h1>{blueWorld.projectHeading}</h1>
            <h5 className="my-3 ">{blueWorld?.subHeading}</h5>
            <p style={{ textAlign: "justify" }}>{blueWorld?.paragraph}</p>
          </div>
          <div className="col-12">
            <CenterHeading title={blueWorld.gallery} />
          </div>
          <div className="col-12"></div>
          <div className="container mb-5">
            <div className="row ">
              <div className="col-12 text-center">
                {buttonname3.map((item, index) => (
                  <div
                    className="d-flex d-inline-flex my-3 "
                    key={index + 1}
                    onClick={() => tabHandler2(item)}
                  >
                    <DashboardBtnList
                      label={item}
                      bntStyle={{
                        borderRadius:
                          index === 0
                            ? "50px 0px 0px 50px"
                            : index === buttonname3.length - 1
                            ? "0px 50px 50px 0px"
                            : "",
                      }}
                      className={
                        selectedTab2 === item
                          ? "dashboardBtnList-item-active px-4 py-2"
                          : "default-color-and-hover px-4 py-2"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            {selectedTab2 === "Pictures" ? (
              <div className="row ">
                {imagesData?.map((item, index) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-5 center-childrens">
                      <a data-fancybox="gallery" href={images[item].default}>
                        <img
                          width="350px"
                          height="250px"
                          alt=""
                          src={images[item].default}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div class="col-md-4 my-4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/gB5wF-h0JyM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : id.id === "31545" ? (
        <div className="row">
          <div className="col-lg-6 col-md-6 mt-5  py-2">
            <img
              src={hawksbayScheme42.bannerImage}
              alt=""
              className="img-fluid"
              style={{ marginTop: "inherit" }}
              // style={{ minHeight: "400px" }}
            />
          </div>

          <div className="col-lg-6 col-md-6 mt-5  p-5 mt-5">
            <h1>{hawksbayScheme42.projectHeading}</h1>
            <h5 className="my-3 ">{hawksbayScheme42?.subHeading}</h5>
            <p style={{ textAlign: "justify" }}>
              {hawksbayScheme42?.paragraph}
            </p>
          </div>
          <div className="col-12">
            <CenterHeading title={hawksbayScheme42.gallery} />
          </div>
          <div className="col-12"></div>
          <div className="container mb-5">
            <div className="row ">
              <div className="col-12 text-center">
                {buttonname3.map((item, index) => (
                  <div
                    className="d-flex d-inline-flex my-3 "
                    key={index + 1}
                    onClick={() => tabHandler2(item)}
                  >
                    <DashboardBtnList
                      label={item}
                      bntStyle={{
                        borderRadius:
                          index === 0
                            ? "50px 0px 0px 50px"
                            : index === buttonname3.length - 1
                            ? "0px 50px 50px 0px"
                            : "",
                      }}
                      className={
                        selectedTab2 === item
                          ? "dashboardBtnList-item-active px-4 py-2"
                          : "default-color-and-hover px-4 py-2"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            {selectedTab2 === "Pictures" ? (
              <div className="row ">
                {imagesData?.map((item, index) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-5 center-childrens">
                      <a data-fancybox="gallery" href={images[item].default}>
                        <img
                          width="350px"
                          height="250px"
                          alt=""
                          src={images[item].default}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div class="col-md-6 my-4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/3o_GMEa2xek"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="col-md-6 my-4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/3lAII-Tdriw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProjectsDetailPage;
