import React from 'react'

const CenterHeading = ({title}) => {
    return (
        <div className="w-100 text-center my-5">
            <h2 className="custom-center-heading">{title}</h2>
        </div>
    )
}

export default CenterHeading
