import React from "react";
import img from "../../../Static/images/profile.png";
import SocialLinks from "../../../Static/icons/SocialLinks.png";
import instagram from "../../../Static/icons/insta.png";

import facebook from "../../../Static/icons/6.png";
import youtube from "../../../Static/icons/5.png";

const TeamCard = ({
  cardImg,
  cardName,
  desgination,
  description,
  fblink,
  twitter,
  insta,
  web,
}) => {
  return (
    <>
      <div className="card flex-center team-card-container cursor">
        <img
          className="card-img-top mt-4"
          src={cardImg ? cardImg : img}
          alt="Card cap"
          style={{ borderRadius: "50%", height: "150px", width: "150px" }}
        />
        <div className="card-body text-center">
          <h5 className="card-title team-card-title mb-1">{cardName}</h5>
          <span className="team-card-designation">{desgination}</span>
          <p className="card-text mt-2 team-card-details px-3">{description}</p>
          <div className=''>
          <a
            target="_blank"
            href="https://www.facebook.com/dreamfinderrealestate"
          >
            <img src={facebook} alt="" className="my-3 px-3" />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCr4QoQ97tHT4WlZM6coe73Q"
          >
            <img src={youtube} alt="" className="my-3" />
          </a>
          <a
            // target="_blank"
            // href="https://www.youtube.com/channel/UCr4QoQ97tHT4WlZM6coe73Q"
          >
            <img src={instagram} alt="sd" className="my-3 px-3" />
          </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
