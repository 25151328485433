import React from "react";
import ProjectsCard from "../Layouts/ProjectsCards/ProjectsCard";
import CenterHeading from "../ReuseableComponents/CenterHeading";

const Project = () => {
  return (
    <>
      <div className="row About-header p-5 mx-0 ">
        <div className="container">
          <div className="col-sm-12">
            <h4 className="about-header-text">Projects</h4>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row my-5">
          <CenterHeading title="Our Projects" />
          {[31545 ,76343, 21242 ].map((item,index) => (
            <div className="col-lg-4 col-md-6" key={index + 1}>
              <ProjectsCard id={item} ind={index}/>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Project;
