import React, { useState } from "react";

// react-id-swiper
import "swiper/css/swiper.css";
import Swiper from "react-id-swiper";

// custom css
import "./Carousal.css";

// images
import image1 from "../../../Static/Slider/1.png";
import image2 from "../../../Static/Slider/2.png";
import image3 from "../../../Static/Slider/3.png";
import image4 from "../../../Static/Slider/4.jpg";
import { Link } from "react-router-dom";

// slider configuration
const HeroSliderConfigs = {
  containerClass: "swiper-container hero-slider",
  parallax: true,
  centeredSlides: true,
  grabCursor: true,
  speed: 3000,
  spaceBetween: 0,
  effect: "slide",
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: false,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

// slider component
const Carousal = () => {
  const [parallaxSwiper, setParallaxSwiper] = useState(null);
  const parallaxAmount = parallaxSwiper ? parallaxSwiper.width * 0.95 : 0;
  const parallaxOpacity = 0.5;
  return (
    <Swiper {...HeroSliderConfigs} getSwiper={setParallaxSwiper}>
      <div className="hero-slide">
        <div
          className="slide-image"
          data-swiper-parallax={parallaxAmount}
          data-swiper-parallax-opacity={parallaxOpacity}
        >
          <img
            src={image1}
            width="100%"
            height="100%"
            alt="image1"
            style={{ filter: "brightness(0.6)" }}
            // className="img-overlay"
          ></img>
        </div>
      </div>

      <div className="hero-slide">
        <div
          className="slide-image"
          data-swiper-parallax={parallaxAmount}
          data-swiper-parallax-opacity={parallaxOpacity}
        >
          <img
            src={image3}
            width="100%"
            alt="image3"
            className="img-overlay"
          ></img>
        </div>
        <div className="col-md-6 offset-md-3 my-auto text-center text-white caption">
          <h1 className="text-uppercase mb-2 font-weight-bold">
            HawksBay Scheme 42
          </h1>
          <p className="mb-5 small">
            Its a very good time to invest in Hawks bay Scheme-42 Karachi. Hawks
            bay scheme-42 is planned, developed, announced by Karachi
            Development authority. It is located on Pakistan Coastal Belt, Hawks
            bay, Karachi.
          </p>
          <div className="d-flex justify-content-center mt-3">
            <Link
              to="/contact"
              className="btn btn-outline rounded-pill  btn-hover-custom"
              style={{
                border: "3px solid #fed000",
                fontWeight: "700",
                padding: "10px 30px",
                color: "#ffffff",
              }}
            >
              <span style={{ fontSize: "14px" }}>Contact Us</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="hero-slide">
        <div
          className="slide-image"
          data-swiper-parallax={parallaxAmount}
          data-swiper-parallax-opacity={parallaxOpacity}
        >
          <img
            src={image2}
            width="100%"
            alt="image2"
            className="img-overlay"
          ></img>
        </div>
        <div className="col-md-6 offset-md-3 my-auto text-center text-white caption">
          <h1 className="text-uppercase mb-2 font-weight-bold">
            Blue World City
          </h1>
          <p className="mb-5 small">
            If you desire a house at the city where you get, Burj Ul Arab
            World's Tallest Horse Mascot Blue Mosque Water Theme Park Twin
            Towers Lake Villas Roomi Square Basic all facilities 240 feet wide
            roads 24/7 security with fully gated community Blue World City
            features the world's tallest night-lit Horse Mascots reaching 125
            feet into the sky, covering an area of 33 kanals.
          </p>
          <div className="d-flex justify-content-center mt-3">
            <Link
              to="/contact"
              className="btn btn-outline rounded-pill  btn-hover-custom"
              style={{
                border: "3px solid #fed000",
                fontWeight: "700",
                padding: "10px 30px",
                color: "#ffffff",
              }}
            >
              <span style={{ fontSize: "14px" }}>Contact Us</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="hero-slide">
        <div
          className="slide-image"
          data-swiper-parallax={parallaxAmount}
          data-swiper-parallax-opacity={parallaxOpacity}
        >
          <img
            src={image4}
            width="100%"
            alt="image3"
            className="img-overlay"
          ></img>
        </div>
        <div className="col-md-6 offset-md-3 my-auto text-center text-white caption">
          <h1 className="text-uppercase mb-2 font-weight-bold">Baharia Town</h1>
          <p className="mb-5 small">
            Bahria Town’s philosophy is to view each project in its entirety,
            beyond mere physical planning, to include the optimization of social
            and economic needs, while treating both small and mega projects with
            equal emphasis to detail and quality
          </p>
          <div className="d-flex justify-content-center mt-3">
            <Link
              to="#"
              className="btn btn-outline rounded-pill  btn-hover-custom"
              style={{
                border: "3px solid #fed000",
                fontWeight: "700",
                padding: "10px 30px",
                color: "#ffffff",
              }}
            >
              <span style={{ fontSize: "14px" }}>Contact Us</span>
            </Link>
          </div>
        </div>
      </div>
    </Swiper>
  );
};

export default Carousal;
