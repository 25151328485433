/* eslint-disable */

import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../../Static/images/logo.png";
import { NavLink, Link } from "react-router-dom";



const Navbars = (props) => {

  
  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-dark custom-nav " >
        <div className="container" >
          <Link to="/">
            <img
              alt=""
              src={logo}
              style={{ minWidth: "150px", minHeight: "60px", height: 100,padding:'10px' }}
            />
          </Link>

          <button
            className="navbar-toggler toggle-iconcustome ms-auto mt-3 "
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon "></span>
          </button>

          <div className="collapse navbar-collapse " id="navbarSupportedContent" >
            <div className="ml-auto" >

              <div className="row">
                <ul className="navbar-nav mb-auto mt-1 mb-lg-0 ms-auto pt-1 text-capitalize fontsizefamily aa ">
                  <li className="nav-item px-3 ">
                    <NavLink
                      className="nav-link secnav  "
                      aria-current="page"
                      to="/"
                      exact
                      activeClassName="active"
                    >
                      Home
                    </NavLink>
                  </li>
                
                  <li className="nav-item px-3">
                    <NavLink
                      className="nav-link secnav "
                      to="/projects"
                      exact
                    >
                      project
                    </NavLink>
                  </li>
               
                  <li className="nav-item px-3">
                    <NavLink
                      className="nav-link secnav "
                      to="/certificates"
                      exact
                    >
                      Certificates
                    </NavLink>
                  </li>
           
                  <li className="nav-item px-3">
                    <NavLink
                      className="nav-link secnav "
                      to="/ourTeam"
                      exact
                    >
                      Our Team
                    </NavLink>
                  </li>
              
                  <li className="nav-item px-3">
                    <NavLink
                      className="nav-link secnav "
                      to="/about"
                      exact
                    >
                      About
                    </NavLink>
                  </li>
                 
                  <li className="nav-item px-3">
                    <NavLink
                      className="nav-link secnav "
                      to="/contact"
                      exact
                    >
                      Contact
                    </NavLink>
                  </li>

                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

   
    </>
  );
};

export default Navbars;
