import React from "react";
import DarkOutlinedBtn from "./DarkOutlinedBtn";
const ProjectSection = ({ onClick, description, title, banner, toggle,btnnone }) => {
  const bannerSection = (
    <div className="col-lg-6 col-12 mb-4">
      <img src={banner} alt="" width={"100%"} style={{minHeight:'250px'}} />
    </div>
  );
  const descriptionSection = (
    <div className="col-lg-6 col-12 d-flex flex-column justify-content-start mt-4 mb-4">
      <h3>{title}</h3>
      <p>{description}</p>
      {btnnone ? (
        ""
      ) : (
        <DarkOutlinedBtn label={"Discover More"} onClick={onClick} />
      )}
    </div>
  );
  return (
    <div className="row">
      {toggle ? (
        <>
          {bannerSection}
          {descriptionSection}
        </>
      ) : (
        <>
          {descriptionSection}
          {bannerSection}
        </>
      )}
    </div>
  );
};

export default ProjectSection;
