import React from "react";
import home_about from "../../Static/images/home_about.png";
import TeamCard from "../Layouts/TeamCard/TeamCard";
import CenterHeading from "../ReuseableComponents/CenterHeading";
import pp from "../../Static/team/shabbir.png";
import uzair from "../../Static/team/uzair.png";
import usama from "../../Static/team/usama.png";
const AboutUs = () => {
  return (
    <>
      <div className="row About-header p-5 mx-0 ">
        <div className="container">
          <div className="col-sm-12">
            <h4 className="about-header-text">About Us</h4>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p style={{ textAlign: "justify", color: "#444" }}>
              {`Dream Finder initiated from a yearning to save customers important and valuable money and facilitate them a less nerve-racking home purchasing, selling and financing escapade. Our prosperity is the fruitful result of our tireless commitment to provide assistance to our customers. Our Company was founded in 2015 and haven’t stopped climbing the stairs of success since then. We mean to consummate the customer experience for purchasing, selling, and renegotiating homes that brings a change in the world wide view of price and service. We mean to ensure customers in their most significant choices and decisions that automatically makes their experience with us and with the retail more affordable and exciting. Dream Finder is a modern, fast-paced, enterprising and vivacious organization that adopts unprecedented customer driven strategies.
Dream Finder has profoundly served services in DEFCLAREA (Defence & Clifton Association of Real Estate Agents) as a member since 2017. Dream Finder has also been involved in the projects of DHA, BAHRIA TOWN, Hawksbay Scheme 42, Blue World City (Islamabad) and many more renowned projects.
Since past five years, Dream Finder are working in Hawksbay Scheme 42 (government scheme, costal belt)
Past beyond the delightful and beautiful properties and the facilities of our hardworking representatives, our clients can only find one company Dream Finder that conveys what has actually been said, a way of life that takes everything into account. With a large network of locations and houses available to be purchased and bought, our website also caters to you thoroughly throughout entire the property details beforehand taking any decision. We incorporate an enormous stock of extravagance homes and many more.
Dream Finder has every way to push Real Estate Forward!
`}
            </p>
          </div>

          <div className="col-md-6 col-sm-12 px-5">
            <img
              src={home_about}
              className="img-fluid img-about"
              alt="..."
              style={{
                maxHeight: "500px",
                width: "100%",
                borderRadius: "20px",
              }}
            />
          </div>
        </div>
        <CenterHeading title={"Founders"} />
      </div>
      <div className="container my-5 ">
        <div className="row ">
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardImg={pp}
              cardName="Shabbir Ali"
              desgination="Co-Founder"
              description="Mr. Shabbir Ali is an eminent businessman working in the field since last 35 years. He has both reputation and experience that help Dream Finders to move forward. "
            />
          </div>
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardImg={usama}
              cardName="Usama Hameed"
              desgination="Co-Founder"
              description="Mr. Usama Hameed is known in the industry for his reputation of pushing and motivating the entire team further, making sure the job is well done. "
            />
          </div>
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardImg={uzair}
              cardName="Uzair Ahmed"
              desgination="Co-Founder"
              description="Mr. Uzair Ahmed is highly respected by his peers , in the real estate market. He is the person who remains very much involved in Dream Finder's day to day environment and operations."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
