import React from "react";
import Carousal from "../Layouts/Slider/Carousal";
import Testemonials from "../Layouts/testimonials/Testemonials";
import CenterHeading from "../ReuseableComponents/CenterHeading";
import ProjectSection from "../ReuseableComponents/ProjectSection";
import hawksabyBanner from "../../Static/Slider/3.png";
// import hawksabyBanner2 from "../../Static/Slider/1.png";
import hawksabyBanner3 from "../../Static/Slider/2.png";
import TeamCard from "../Layouts/TeamCard/TeamCard";
import DarkOutlinedBtn from "../ReuseableComponents/DarkOutlinedBtn";
import ContactUsSectionHome from "../Layouts/ContactUsSectionHome/ContactUsSectionHome";
import HomeServiceCard from "../Layouts/HomeServiceCard/HomeServiceCard";
import home_about from "../../Static/images/home_about.png";
import { Link } from "react-router-dom";
import defclairea from "../../Static/images/defclairea.png";
import zameen from "../../Static/images/zameen.png";
import alan from "../../Static/images/alan.png";
import ClientSlider from "../Layouts/clientSlider/ClientSlider";
import bahariaTown from "../../Static/images/bahariaTown.jpg";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import pp from "../../Static/team/shabbir.png";
import uzair from "../../Static/team/uzair.png";
import usama from "../../Static/team/usama.png";
const Home = (props) => {
  const imgData = [defclairea, zameen, alan];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 px-0">
            <Carousal />
          </div>
        </div>
        <div className="container">
          <Zoom>
            <div className="row mt-5">
              <div className="col-lg-6 d-flex justify-content-center px-0">
                <img
                  src={home_about}
                  alt=""
                  style={{ width: "80%" }}
                  // className="d-none d-lg-block"
                />
                {/* <img
                src={home_about}
                alt=""
                className="d-lg-none"
                style={{ width: "100%" }}
              /> */}
              </div>
              <div className="col-lg-6 py-5 mt-5 px-5">
                <h1 style={{ fontSize: "43px" }}>
                  Our Success <br />
                  Your Future
                </h1>
                <p className="mt-3">
                  Dream Finder initiated from a yearning to save customers
                  important and valuable money and facilitate them a less
                  nerve-racking home purchasing, selling and financing escapade.
                  Our prosperity is the fruitful result of our tireless
                  commitment to provide assistance to our customers.
                </p>
                <Link
                  to="/about"
                  className="btn btn-outline rounded-pill  btn-hover-custom mt-4"
                  style={{
                    border: "3px solid #fed000",
                    fontWeight: "700",
                    padding: "10px 30px",
                    color: "#000000",
                  }}
                >
                  <span style={{ fontSize: "14px" }}>View More</span>
                </Link>
              </div>
            </div>
          </Zoom>
        </div>
        <div id="serviceId" className="pb-5">
          <HomeServiceCard />
        </div>
        <CenterHeading title={"Explore Projects"} />
        <div className="row">
          <div className="container">
            <div className="col-12 mt-5">
              <Fade left>
                <ProjectSection
                  title={"Hawksbay Scheme 42"}
                  description={
                    "Its a very good time to invest in Hawks bay Scheme-42 Karachi. Hawks bay scheme-42 is planned, developed, announced by Karachi Development authority. It is located on Pakistan Coastal Belt, Hawks bay, Karachi."
                  }
                  onClick={() => {
                    props?.history?.push("/projects-inner-details/31545")
                  }}
                  banner={hawksabyBanner}
                  toggle={true}
                />
              </Fade>
            </div>
            <div className="col-12 mt-5">
              <Fade right>
                <ProjectSection
                  title={"Blue World City"}
                  description={`If you desire a house at the city where you get, 
                  Burj Ul Arab 
                  World's Tallest Horse Mascot 
                  Blue Mosque 
                  Water Theme Park 
                  Twin Towers
                  Lake Villas
                  Roomi Square 
                  Basic all facilities 
                  240 feet wide roads 
                  24/7 security with fully gated community 
                  Blue World City features the world's tallest night-lit Horse Mascots reaching 125 feet into the sky, covering an area of 33 kanals.`}
                  onClick={() => {
                    props?.history?.push("/projects-inner-details/76343")
                   
                  }}
                  banner={hawksabyBanner3}
                />
              </Fade>
            </div>
            <div className="col-12 mt-5">
              <Fade left>
                <ProjectSection
                  title={"Baharia Town"}
                  description={
                    "Bahria Town’s philosophy is to view each project in its entirety, beyond mere physical planning, to include the optimization of social and economic needs, while treating both small and mega projects with equal emphasis to detail and quality"
                  }
                  onClick={() => {
                    props?.history?.push("/projects-inner-details/21242")

                  }}
                  banner={bahariaTown}
                  toggle={true}
                />
              </Fade>
            </div>
            <div className="col-12 w-100 flex-center mt-5 pt-4">
              <DarkOutlinedBtn
                label="View More"
                onClick={() => props.history.push("/projects")}
              />
            </div>
          </div>
        </div>
        <CenterHeading title="Our Team " />
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 col-12 px-4 mt-3">
              <TeamCard
              cardImg={pp}
                cardName="Shabbir Ali"
                desgination="Founder"
                description="Mr. Shabbir Ali is an eminent businessman working in the field since last 35 years.He has both reputation and experience that help Dream Finders to move forward. "
              />
            </div>
            <div className="col-lg-4 col-12 px-4 mt-3">
              <TeamCard
              cardImg={usama}

                cardName="Usama Hameed"
                desgination="Co-Founder"
                description="Mr. Usama Hameed is known in the industry for his reputation of pushing and motivating the entire team further, making sure the job is well done. "
              />
            </div>
            <div className="col-lg-4 col-12 px-4 mt-3">
              <TeamCard
              cardImg={uzair}

                cardName="Uzair Ahmed"
                desgination="CEO"
                description="Mr. Uzair Ahmed is highly respected by his peers, in the real estate market. He is the person who remains very much involved in Dream Finder's day to day environment and operations."
              />
            </div>
            <div className="col-12 w-100 flex-center mt-5 pt-4">
              <DarkOutlinedBtn
                label="View More"
                onClick={() => props.history.push("/ourTeam")}
              />
            </div>
          </div>
        </div>
      </div>

      <ClientSlider data={imgData} />

      <div className="mt-5 pt-5">
        <ContactUsSectionHome />
      </div>
      <div className="">
        <Testemonials />
      </div>
    </>
  );
};

export default Home;
