import React from "react";
// import contactus from "../../Static/images/contactus.png";
import phone from "../../Static/icons/phone-call.svg";

import ContactUsSectionHome from "../Layouts/ContactUsSectionHome/ContactUsSectionHome";
import CenterHeading from "../ReuseableComponents/CenterHeading";
const Contact = () => {
  return (
    <>
      <div className="">
        <div className="">
        <div className="row About-header p-5 mx-0 ">
        <div className="container">
          <div className="col-sm-12">
            <h4 className="about-header-text">Contact Us</h4>
          </div>
        </div>
      </div>
          {/* <div className="row mt-5 mb-5 ">
            <div className="col-12 px-0">
              <h3
                className="text-center w-100"
                style={{
                  fontSize: "30px",
                  fontFamily: "nunito",
                }}
              >
                Contact Us
              </h3>
            </div>
          </div> */}
            <CenterHeading title={"Contact Us"} />
        </div>
        <ContactUsSectionHome />
        <div className="contact-info-section mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h5
                  className="text-center"
                  style={{
                    fontSize: "30px",
                    fontFamily: "nunito",
                  }}
                >
                  Call us today for more details
                </h5>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4 col-md-4 col-sm-6 mt-3 flex-center">
                <div className="row">
                  <div className="col-4 ">
                    <img src={phone} alt="alternate" />
                  </div>
                  <div className="col-8 mt-2">
                    <h6
                      // className="text-center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Shabbir Ali
                    </h6>

                    <p>0300-9217150</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mt-3 flex-center">
                <div className="row">
                  <div className="col-4">
                    <img src={phone} alt="alternate" />
                  </div>
                 <div className="col-8 mt-2">
                    <h6
                      // className="text-center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Usama Hameed
                    </h6>

                    <p>0300-9225028</p>
                  </div>
                </div>
              </div> 

              <div className="col-lg-4 col-md-4 col-sm-6 mt-3 flex-center">
                <div className="row">
                  <div className="col-4">
                    <img src={phone} alt="alternate" />
                  </div>
                  <div className="col-8 mt-2">
                    <h6
                      // className="text-center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Uzair Ahmed
                    </h6>

                    <p>0345-3031503</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;