import React from "react";
import "./clientSlider.css";
import Slider from "react-slick";
const ClientSlider = ({ data }) => {
  var settings = {
    speed: 3000,
    autoplaySpeed: 5000,
    lazyLoad: "progressive",
    transitionDuration: 3000,
    arrows: false,
    infinite: true,
    autoplay: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="container">
      <div className="mt-5 pt-5 clientSliderParent">
        <Slider {...settings}>
          {data.map((item, index) => (
            <div className="center-childrens  py-2" key={index + 1}>
              <img
                src={item}
                alt=""
                style={{ width: "178px", height: "126px" }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientSlider;
