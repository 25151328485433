import React from "react";

const ContactUsSectionHome = () => {
  return (
    <div className="contactus-container ">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 py-5 d-none d-lg-block">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.0146126767636!2d67.07520991487651!3d24.897483184036457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ed944ab48c9%3A0xc89625d8cdf28b0c!2zTWFzaHJpcSBDZW50ZXIg2YXYtNix2YIg2LPbjNmG2bnYsQ!5e0!3m2!1sen!2s!4v1638036066219!5m2!1sen!2s"
              width="95%"
              height="98%"
              frameborder="0"
              style={{ border: "0px", borderRadius: "5px" , minHeight:'300px' }}
              title="contactlocation"
              
            ></iframe>
          </div>
          <div className="col-lg-6 col-12 py-5">
            <input
              type="text"
              className="contactus_input pl-3"
              placeholder="Enter Your Full Name"
            />
            <input
              type="email"
              className="contactus_input pl-3 mt-3"
              placeholder="Enter Your Email Address"
            />
            <input
              type="number"
              className="contactus_input pl-3 mt-3"
              placeholder="Enter Your Phone Number"
            />
            <textarea
              className="pl-3 pt-3 mt-3"
              placeholder="Message"
              style={{ width: "100%", height: "150px" , border:'transparent',borderRadius:'5px'}}
            />
            <div className='w-100 d-flex justify-content-end mt-2'>
            <button className='contactUs-btn px-4 py-2'>
            Send Message 
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSectionHome;
