import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { projectCardData } from "../../../data/projectData.js";
const ProjectsCard = ({ id ,ind}) => {
  // eslint-disable-next-line
  const [heading, setHeading] = useState("");
  useEffect(() => {
    if (id.id === "21242") {
      setHeading("Bahria Town");
    } else if (id.id === "76343") {
      setHeading("Blue World City");
    }
  }, [id]);

  return (
    <>
      <div>
        <div class="image-flip">
          <div class="mainflip flip-0">
            <div class="frontside">
              <div class="">
                <div class="text-center">
                  <img
                    class="img-fluid"
                    src={projectCardData[ind][0]?.bahriaTownImage}
                    alt="card"
                    style={{ height: "200px", width: "100%" }}
                  />
                  <div
                    className="text-center About-header p-4"
                    style={{ width: "100%" }}
                  >
                    <h4 className="">{projectCardData[ind][0]?.bahriaTownHeading}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="backside">
              <div class="" style={{ height: "280px" }}>
                <div class=" text-center">
                  <div className=" About-header p-3 ">
                    <h4 class="mt-2">Dream Finder</h4>
                  </div>
                  <div className="pt-3 px-3 text-center">
                    <p class="">
                      {projectCardData[ind][0]?.bahriaTownDetails}
                    </p>
                  </div>
                  <div>
                    <Link
                      class="dark-outlined-btn py-2 px-4"
                      to={`/projects-inner-details/${id}`}
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsCard;
