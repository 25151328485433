import React from "react";
import TeamCard from "../Layouts/TeamCard/TeamCard";
import CenterHeading from "../ReuseableComponents/CenterHeading";
import pp from "../../Static/team/shabbir.png";
import uzair from "../../Static/team/uzair.png";
import usama from "../../Static/team/usama.png";

const OurTeam = () => {
  return (
    <div>
      <div className="row About-header p-5 mx-0 ">
        <div className="container">
          <div className="col-sm-12">
            <h4 className="about-header-text">Dream Finder's Team</h4>
          </div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="container">
          <div className="col-12 py-5 ">
            <span style={{ fontSize: "18px", color: "#444" }}>
              Our remarkably exceeding team aims towards efficiency, around
              productivity, straightforwardness with facts, never compromising
              quality, transparency and advocating our customer's full interests
              and requirements. They share thoughts with each other, team up and
              make sure every day goes effective!
            </span>
          </div>
        </div>
      </div>
      <CenterHeading title="Meet The Team" />
      <div className="container">
        <div className="row my-5 pb-5">
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardImg={pp}
              cardName="Shabbir Ali"
              desgination="Co-Founder"
              description="Mr. Shabbir Ali is an eminent businessman working in the field since last 35 years. He has both reputation and experience that help Dream Finders to move forward. "
            />
          </div>
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardImg={usama}
              cardName="Usama Hameed"
              desgination="Co-Founder"
              description="Mr. Usama Hameed is known in the industry for his reputation of pushing and motivating the entire team further, making sure the job is well done. "
            />
          </div>
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardImg={uzair}
              cardName="Uzair Ahmed"
              desgination="Co-Founder"
              description="Mr. Uzair Ahmed is highly respected by his peers, in the real estate market. He is the person who remains very much involved in Dream Finder's day to day environment and operations."
            />
          </div>
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardName="Waqas Hameed"
              desgination="Agent"
              description="Mr. Waqas Hameed is a sales person at Dream Finder, Waqas Hameed role is to act as a mediator for every transaction."
            />
          </div>
          <div className="col-lg-4 col-12 px-4 mt-3">
            <TeamCard
              cardName="Adnan Ghouri"
              desgination="Agent"
              description="Mr. Adnan Ghouri is highly respected sales person at Dream Finder, in the real estate market. He is responsible to oversee that both parties receive an honest and fair deals."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
