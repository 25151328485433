import "./App.css";
import Navbar from "./Components/Layouts/Navbar/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Pages/Home";
import Contact from "./Components/Pages/Contact";
import Certificates from "./Components/Pages/Certificates";
import OurTeam from "./Components/Pages/OurTeam";
import Footer from "./Components/Layouts/Footer/Footer";
import Error404 from "./Components/Pages/Error404";
import ScrollToTop from "./Components/ReuseableComponents/ScrollToTop";
import Project from "./Components/Pages/Project";
import AboutUs from "./Components/Pages/AboutUs";
import ProjectsDetailPage from "./Components/Pages/ProjectsDetailPage";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        {/*          Root Page             */}

        {/* <Route exact path="/" render={(props) => <Navbar {...props} />} /> */}

        {/*          Navbar             */}
        {/* <Route exact path="/:page" render={(props) => <Navbar {...props} />} /> */}
        <Navbar  />
        <Switch>
          {/*          Front Home Page             */}
          <Route exact path="/" render={(props) => <Home {...props} />} />

          {/*          Home Page             */}
          <Route exact path="/home" render={(props) => <Home {...props} />} />

          {/*          Main About Page             */}

          <Route exact path="/about">
            <AboutUs />
          </Route>

          {/*          Contact Page             */}

          <Route exact path="/contact">
            <Contact />
          </Route>

          {/*          Certificates Page             */}

          <Route exact path="/certificates">
            <Certificates />
          </Route>

          {/*          OurTeam Page          */}

          <Route exact path="/ourTeam">
            <OurTeam />
          </Route>

          {/*          Project Page          */}

          <Route exact path="/projects">
            <Project />
          </Route>

          {/*          Project Inner Detail Page          */}

          <Route exact path="/projects-inner-details/:id">
            <ProjectsDetailPage />
          </Route>

          {/* 404 Page */}
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>

        {/*          Footer             */}
        <Footer />
{/* 
        <Route exact path="/">
          <Footer />
        </Route>
        <Route exact path="/:page" render={(props) => <Footer {...props} />} /> */}

      </Router>
    </div>
  );
}

export default App;
