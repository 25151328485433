import React from "react";
import Slider from "react-slick";
import "./testemonials.css";
import img1 from "../../../Static/images/clip2.jpeg";
import img2 from "../../../Static/images/clip3.jpeg";
import img3 from "../../../Static/images/clip4.jpeg";

const testemonialsData = [
  {
    img: img1,
    review: `
    Nobody knows Hawksbay Scheme 42 better than Dreamfinder.I felt 110% confident by dealing with Dream Finder.`,
    name: "Shabbir Fida Hussain Kehrahi",
    link: "",
  },
  {
    img: img2,
    review: `Dream Finder is by far the BEST realtor we’ve ever worked with.They keeps their client’s best interests in sharp focus.`,
    name: "Waqas Khan",
    link: "",
  },
  {
    img: img3,
    review: `Dream finder gave us many helpful suggestions on how to Invest in profitable place.They meets all expectations.`,
    name: "Nadeem Rahim",
    link: "",
  },
];

const Testemonials = ({ data }) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="w-100 testemonail-container p-5">
      <div className="container mb-5  ">
        <div style={{ marginTop: "80px" }}>
          <Slider {...settings}>
            {testemonialsData.map((item, index) => (
              <div
                className="center-childrens main-testemonail"
                key={index + 1}
              >
                <img src={item.img} alt="" className="testemonial-img" />
                <p className="testemonial-review mt-4">{item.review}</p>
                <p className="light-Color-Para">-{item.name}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testemonials;
